import React, { useEffect, useState } from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation,
    useNavigate
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ScrollToTop from './config/helpers/scrollToTop'
import {
    Navigation,
    Footer,
    Sidebar,
    Home,
    About,
    Team,
    Pricing,
    Thanks,
    Contact,
    Disclaimer,
    Privacy,
    Explore,
    SignIn,
    // SignUp,
    // DisclaimerScreen,
    // PreAssessment,
    PasswordEntry,
    Account,
    Content,
    MyTeam,
    Search,
    MemberResults,
    Profile,
    Terms,
    Releases,
    Cookies,
    Acceptable,
    NotFound,
    Maintenance,
    Error,
    BoxContent,
    PackContent,
    BoxEdit,
    BoxCreate,
    PackEdit,
    PackCreate,
    CardEdit,
    CardCreate,
    Verification,
    ForgetPassword,
    ResetPassword,
    Admin,
    AdminEdit
} from './pages'
import { toast } from 'react-toastify'
import { ToastFrame } from './components/toast'
import authService from './services/auth.service'
import memberService from './services/members.service'
import subsService from './services/subs.service'
import { getMemberSelf } from './actions/members'
import { userSubs } from './config/slice/subs.slice'
import { getLookups } from './actions/others'
import { getBlockedMembers } from './actions/members'

function Wrapper({ children }) {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { pathname, search } = location
    const [tokenValue, setTokenValue] = useState(null)
    const [loading, setLoading] = useState(false)
    const subs = useSelector(state => state.subs.value)
    const privatePaths = {
        '/account': '/account',
        '/content': '/content',
        '/my-team': 'my-/team',
        '/search': '/search',
        '/search/memberresults': '/ memberresults',
        '/verification': '/verification',
        '/admin': '/admin',
        '/admin/edit': '/admin/edit'
    }
    const authPaths = {
        '/signin': '/signin',
        '/signup': '/signup',
        '/password-entry': '/password-entry'
    }
    const nonVerifiedPaths = {
        '/pricing': '/pricing',
        '/account': '/account'
    }
    const adminPaths = {
        '/admin': '/admin',
        '/admin/edit': '/admin/edit'
    }

    const rootPath = () => {
        return `/${pathname.split('/')[1]}`
    }

    const belongTeamPromoState = member => {
        if (!member.appliedTeamPromo) {
            return false
        }
        return member.appliedTeamPromoState
    }
    async function fetchData() {
        setLoading(true)
        dispatch(
            getMemberSelf({
                callBacks: {
                    success: async memberRes => {
                        if (memberRes && Object.keys(memberRes).length > 0) {
                            dispatch(getBlockedMembers())

                            if (memberRes.team?.agreement) {
                                await getSubs(memberRes)
                            }

                            if (!memberRes.isVerified) {
                                if (!nonVerifiedPaths[rootPath()]) {
                                    navigate(
                                        `/verification?email=${memberRes.email}`
                                    )
                                }
                            } else {
                                if (authPaths[pathname]) {
                                    if (memberRes.role === 'Admin') {
                                        navigate('/admin')
                                    } else if (
                                        memberRes.intent == 'Provider' &&
                                        (subs != null ||
                                            belongTeamPromoState(memberRes))
                                    ) {
                                        navigate('/content')
                                    } else {
                                        navigate('/account')
                                    }
                                }
                                if (
                                    memberRes.intent == 'Client' &&
                                    privatePaths[pathname]
                                ) {
                                    navigate('/account')
                                }
                                if (adminPaths[pathname]) {
                                    if (memberRes.role !== 'Admin') {
                                        if (
                                            memberRes.intent == 'Provider' &&
                                            (subs != null ||
                                                belongTeamPromoState(memberRes))
                                        ) {
                                            navigate('/content')
                                        } else {
                                            navigate('/account')
                                        }
                                    }
                                }
                            }

                            setLoading(false)
                        } else {
                            setLoading(false)
                            toast.error('Unknown Error', {
                                position: 'top-right',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined
                            })
                        }
                    },
                    err: error => {
                        authService.logout()
                        setLoading(false)
                        toast.error('Unknown Error', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        })
                    }
                }
            })
        )
        async function getSubs(member) {
            try {
                const sub = await subsService.getSubs()
                if (sub.member) {
                    dispatch(userSubs(sub))
                    if (
                        rootPath() == '/content' &&
                        !(
                            sub?.subscription.status ||
                            belongTeamPromoState(member)
                        )
                    ) {
                        navigate('/account')
                    }
                } else {
                    dispatch(userSubs(null))
                }
            } catch (e) {
                toast.error('Unknown Error', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
                return
            }
        }
    }

    useEffect(() => {
        try {
            authService.verifyToken().then(async token => {
                setTokenValue(token)
                dispatch(getLookups())
                if (!token) {
                    if (privatePaths[rootPath()]) {
                        await authService.logout()
                        navigate('/signin')
                    }
                    setLoading(false)
                } else {
                    await fetchData()
                }
            })
        } catch (error) {
            authService.logout()
            toast.error('Unknown Error', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark'
            })
        }
    }, [dispatch, tokenValue, pathname, search])

    return (
        <>
            {loading ? (
                <div className="flex-c flex-grow-1 align-items-center justify-content-center bckg-white">
                    <span className="gray-600 font-28">
                        <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                    </span>
                </div>
            ) : (
                <>{children}</>
            )}
        </>
    )
}

function App() {
    return (
        <div className="app">
            <Router>
                <ScrollToTop />
                <div className="flex-r flex-grow-1">
                    <Sidebar />
                    <div className="flex-c flex-grow-1">
                        <Navigation />
                        <Wrapper>
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/about" element={<About />} />
                                <Route path="/team" element={<Team />} />
                                <Route path="/pricing" element={<Pricing />} />
                                <Route path="/signin" element={<SignIn />} />
                                {/* <Route path="/disclaimer-screen" element={<DisclaimerScreen />} />
                                <Route path="/signup" element={<SignUp />} />
                                <Route path="/pre-assessment" element={<PreAssessment />} /> */}

                                <Route
                                    path="/password-entry"
                                    element={<PasswordEntry />}
                                />
                                <Route
                                    path="/verification"
                                    element={<Verification />}
                                />
                                <Route
                                    path="/forget-password"
                                    element={<ForgetPassword />}
                                />
                                <Route
                                    path="/reset-password"
                                    element={<ResetPassword />}
                                />
                                <Route path="/contact" element={<Contact />} />
                                <Route path="/disclaimer" element={<Disclaimer />} />
                                <Route path="/privacy" element={<Privacy />} />
                                <Route path="/thanks" element={<Thanks />} />
                                <Route path="/explore" element={<Explore />} />
                                <Route path="/terms" element={<Terms />} />
                                <Route
                                    path="/releases"
                                    element={<Releases />}
                                />
                                <Route path="/cookies" element={<Cookies />} />
                                <Route
                                    path="/acceptable"
                                    element={<Acceptable />}
                                />
                                <Route path="*" element={<NotFound />} />
                                <Route path="/maintenance" element={<Maintenance />} />
                                <Route path="/error" element={<Error />} />
                                <Route path="/account" element={<Account />} />
                                <Route path="/content" element={<Content />} />
                                <Route
                                    path="/content/box/edit"
                                    element={<BoxEdit />}
                                />
                                <Route
                                    path="/content/box/create"
                                    element={<BoxCreate />}
                                />
                                <Route
                                    path="/content/box/:id"
                                    element={<BoxContent />}
                                />
                                <Route
                                    path="/content/pack/edit"
                                    element={<PackEdit />}
                                />
                                <Route
                                    path="/content/pack/create"
                                    element={<PackCreate />}
                                />
                                <Route
                                    path="/content/pack/:id"
                                    element={<PackContent />}
                                />
                                <Route
                                    path="/content/card/edit"
                                    element={<CardEdit />}
                                />
                                <Route
                                    path="/content/card/create"
                                    element={<CardCreate />}
                                />
                                <Route path="/my-team" element={<MyTeam />} />
                                <Route path="/search" element={<Search />} />
                                <Route
                                    path="/search/memberresults"
                                    element={<MemberResults />}
                                />
                                <Route path="/profile" element={<Profile />} />
                                <Route path="/admin" element={<Admin />} />
                                <Route
                                    path="/admin/edit"
                                    element={<AdminEdit />}
                                />
                            </Routes>
                        </Wrapper>
                        <Footer />
                    </div>
                </div>
            </Router>
            <ToastFrame />
        </div>
    )
}

export default App
