import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import 'react-toastify/dist/ReactToastify.css'
import assessment1 from '../img/Explore/Assessment1-min.png'
import assessment2 from '../img/Explore/Assessment2-min.png'
import card1 from '../img/Explore/Card1-min.png'
import card2 from '../img/Explore/Card2-min.png'
import card3 from '../img/Explore/Card3-min.png'
import stat1 from '../img/Explore/Stat1-min.png'
import stat2 from '../img/Explore/Stat2-min.png'
import team1 from '../img/Explore/Team1-min.png'
import team2 from '../img/Explore/Team2-min.png'

function Explore() {
    const title = 'Explore | Cardeon Features'
    const url = 'https://cardeon.io/explore'
    const desc =
        'Explore the features offered by Cardeon: Assessment, Cards, Statistics and Teams.'
    const [clinicianValue, setClinician] = useState(true)
    const [clientValue, setClient] = useState(false)
    const [showClass, setShowClass] = useState(false)

    const toggleClinician = () => {
        window.scrollTo(0, 0)
        setClinician(true)
        setClient(false)
    }

    const toggleClient = () => {
        window.scrollTo(0, 0)
        setClinician(false)
        setClient(true)
    }

    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 90) {
            setShowClass(true);
          } else {
            setShowClass(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, [])

    return (
        <div className="explore flex-c">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={desc} />
                <link rel="canonical" href={url} />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={desc} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={desc} />
            </Helmet>
            <div className={`${!showClass ? 'flex-c bckg-g200' : 'flex-c bckg-g200 toggle-sticky'}`}>
                <div className="flex-c container">
                    <div className="flex-c row">
                        <div className="flex-c align-items-center col-12">
                            <div
                                className="flex-r bckg-g400 toggle-wrapper">
                                <Link
                                    type="button"
                                    className="btn"
                                    onClick={toggleClinician}
                                    title="For Clinicians">
                                    For Clinicians
                                </Link>
                                <Link
                                    type="button"
                                    className="btn"
                                    onClick={toggleClient}
                                    title="For Clients">
                                    For Clients
                                </Link>
                                <div
                                    className={
                                        `toggle-slide shadow ${clinicianValue ? 'left' : 'right'}`
                                    }/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-c container my-5">
                <div className="flex-c row mb-5">
                    <div className="flex-c align-items-center text-c col-12">
                        <h1 className="flex-c font-48 fw-300 mt-5 mb-0">How Cardeon Works</h1>
                        <p className="lh-24 mb-5">Discover how Cardeon empowers both clients and clinicians with tools to enhance mental wellness. Explore key features like Assessments, Cards, Stats and Teams to see how it all works for you.</p>
                    </div>
                </div>
                {/* Summary Start */}
                <div className="flex-c row align-items-center mb-5">
                    <div className="flex-c align-items-center col-md-8 col-lg-6">
                        <h2 className="font-48 mb-1">Get Started</h2>
                        <h3 className="font-18 fw-500 italic mb-3">{clinicianValue ? '(Clinicians)' : '(Clients)'}</h3>
                        <div className="flex-c tile tile-full tile-padded">
                            <p className="lh-24 fw-700 m-0">
                                1. Download the App
                            </p>
                            <p className="lh-24 m-0 mb-4">
                                {clinicianValue ?
                                    "Ensure both you and your clients download the Cardeon app to get started."
                                    :
                                    "Download the Cardeon app."
                                }
                            </p>
                            <p className="lh-24 fw-700 m-0">
                                2. Complete Signup & Assessment
                            </p>
                            <p className="lh-24 m-0 mb-4">
                                {clinicianValue ?
                                    "Ensure you and your clients have signed up and your clients have completed the assessment to establish a baseline."
                                    :
                                    "Create an account and complete the assessment to establish a baseline."
                                }
                            </p>
                            <p className="lh-24 fw-700 m-0">
                                {clinicianValue
                                    ? "3. Retrieve Your Team Code"
                                    : "3. Explore Your Cards"
                                }
                            </p>
                            <p className="lh-24 m-0 mb-4">
                                {clinicianValue ?
                                    "Access your unique team code from the teams tab."
                                    :
                                    "Receive personalized cards designed to support your mental wellness based on your assessment results."
                                }
                            </p>
                            <p className="lh-24 fw-700 m-0">
                                {clinicianValue
                                    ? "4. Share Your Code"
                                    : "4. Track Your Stats"
                                }
                            </p>
                            <p className="lh-24 m-0 mb-4">
                                {clinicianValue ?
                                    "Copy your code and provide it to your clients."
                                    :
                                    "View your progress and track both overall and symptom-specific stats as you complete cards."
                                }
                            </p>
                            <p className="lh-24 fw-700 m-0">
                                {clinicianValue
                                    ? "5. Connect with Clients"
                                    : "5. Join a Team"
                                }
                            </p>
                            <p className="lh-24 m-0 mb-4">
                                {clinicianValue ?
                                    "Once your clients activate your code, you'll instantly gain access to their stats and scores, enabling you to provide tailored insights and additional support."
                                    :
                                    "Use your mental health professional's unique team code to connect and share your stats, if you choose."
                                }
                            </p>
                            <p className="lh-24 fw-700 m-0">
                                {clinicianValue
                                    ? "6. Add More Clients"
                                    : "6. Stay Engaged"
                                }
                            </p>
                            <p className="lh-24 m-0 mb-2">
                                {clinicianValue ?
                                    "Repeat these steps to easily add multiple clients to your team."
                                    :
                                    "Continue completing cards and monitoring your stats to see your progress over time."
                                }
                            </p>
                        </div>
                    </div>
                </div>
                {/* Summary End */}
            </div>
            
            <div className="flex-c py-5 bckg-secondary my-5">
                <div className="flex-c container">
                    <div className="flex-c row py-5">
                        <div className="flex-c align-items-center text-c col-12">
                            <h2 className="flex-c font-48 white fw-300 mt-5 mb-0">See Cardeon in Action</h2>
                            <p className="lh-24 white mb-0 mt-5">Explore the visual guide below of Cardeon's core features: Assessment, Cards, Stats and Teams.</p>
                            <p className="lh-24 white mb-5">Discover how they work together to enhance mental wellness.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex-c container mb-5">

                {/* Clinician Team - Client Assessment Start */}
                <div className="flex-c row align-items-center mb-5">
                    <div className="flex-c align-items-center text-c col-12 col-md-10">
                        <h4 className="font-48 mb-0">{clinicianValue ? 'Teams' : 'Assessment'}</h4>
                        <p className="lh-24 mb-5">
                            {clinicianValue ?
                                "Start by sharing your unique team code with your clients to access their scores, track progress, and provide support."
                                :
                                "Complete the assessment to establish a baseline and help Cardeon get to know you a little better."
                            }
                        </p>
                        <img src={clinicianValue ? team1 : assessment2} className="img mt-5" alt={clinicianValue ? 'Teams' : 'Assessment'} title={clinicianValue ? 'Teams' : 'Assessment'} />
                    </div>
                </div>
                {/* Clinician Team - Client Assessment End */}
                
                {clinicianValue ? (
                    <>
                        {/* Clinician Assessment */}
                        <div className="flex-c row align-items-center mt-5">
                            <div className="flex-c align-items-center text-c col-10">
                                <h4 className="font-48 mb-0">Assessment</h4>
                                <p className="lh-24 mb-5">
                                    Ensure your clients complete the assessment. This establishes a baseline and allows your clients to start working on cards.
                                </p>
                            </div>
                        </div>
                        <div className="flex-c flex-md-r row align-items-center my-5">
                            <div className="flex-c align-items-center text-c col-md-6 mb-5 mb-md-0">
                                <img src={assessment1} className="img" alt="Assessment 1" title="Assessment 1" />
                            </div>
                            <div className="flex-c align-items-center text-c col-md-6">
                                <img src={assessment2} className="img" alt="Assessment 2" title="Assessment 2" />
                            </div>
                        </div>
                    </>
                ):(
                    <>
                        {/* Client Cards */}
                        <div className="flex-c row align-items-center mt-5">
                            <div className="flex-c align-items-center text-c col-10">
                                <h4 className="font-48 mb-0">Cards</h4>
                                <p className="lh-24 mb-5">
                                    After the assessment, you'll receive personalized cards based on your scores.
                                </p>
                            </div>
                        </div>
                        <div className="flex-c flex-lg-r row align-items-end my-5">
                            <div className="flex-c align-items-center text-c col-lg-4 mb-5 mb-lg-0 px-lg-5 pt-lg-5">
                                <img src={card1} className="img" alt="Evaluation card" title="Evaluation card" />
                            </div>
                            <div className="flex-c align-items-center text-c col-lg-4 mb-5 mb-lg-0">
                                <img src={card2} className="img" alt="Hand view" title="Hand view" />
                            </div>
                            <div className="flex-c align-items-center text-c col-lg-4 mb-5 mb-lg-0 px-lg-5 pt-lg-5">
                                <img src={card3} className="img" alt="Challenge card" title="Challenge card"/>
                            </div>
                        </div>
                    </>
                )}

                {clinicianValue ? (
                    <>
                    {/* Clinician Cards */}
                    <div className="flex-c row align-items-center mt-5">
                        <div className="flex-c align-items-center text-c col-10">
                            <h4 className="font-48 mb-0">Cards</h4>
                            <p className="lh-24 mb-5">
                                After completing the assessment, your clients will receive personalized cards based on their scores.
                            </p>
                        </div>
                    </div>
                    <div className="flex-c flex-lg-r row align-items-end my-5">
                        <div className="flex-c align-items-center text-c col-lg-4 mb-5 mb-lg-0 px-lg-5 pt-lg-5">
                            <img src={card1} className="img" alt="Evaluation card" title="Evaluation card" />
                        </div>
                        <div className="flex-c align-items-center text-c col-lg-4 mb-5 mb-lg-0">
                            <img src={card2} className="img" alt="Hand view" title="Hand view" />
                        </div>
                        <div className="flex-c align-items-center text-c col-lg-4 mb-5 mb-lg-0 px-lg-5 pt-lg-5">
                            <img src={card3} className="img" alt="Challenge card" title="Challenge card"/>
                        </div>
                    </div>
                </>
                ):(
                    <>
                    {/* Client Stats */}
                        <div className="flex-c row align-items-center mt-5">
                            <div className="flex-c align-items-center text-c col-10">
                                <h4 className="font-48 mb-0">Statistics</h4>
                                <p className="lh-24 mb-5">
                                    Your scores will update as you complete new cards. You may track overall or symptom-specific scores anytime.
                                </p>
                            </div>
                        </div>
                        <div className="flex-c flex-md-r row align-items-center my-5">
                            <div className="flex-c align-items-center text-c col-md-5 mb-5 mb-md-0">
                                <img src={stat1} className="img" alt="Full stats" title="Full stats" />
                            </div>
                            <div className="flex-c col-md-2"/>
                            <div className="flex-c align-items-center text-c col-md-5">
                                <img src={stat2} className="img" alt="Symptom stats" title="Symptom stats" />
                            </div>
                        </div>
                    </>
                )}

                {clinicianValue ? (
                    <>
                    {/* Clinician Stats */}
                        <div className="flex-c row align-items-center mt-5">
                            <div className="flex-c align-items-center text-c col-10">
                                <h4 className="font-48 mb-0">Statistics</h4>
                                <p className="lh-24 mb-5">
                                    Each completed card updates your client's scores in real-time. You may track overall or symptom-specific scores anytime.
                                </p>
                            </div>
                        </div>
                        <div className="flex-c flex-md-r row align-items-center my-5">
                            <div className="flex-c align-items-center text-c col-md-5 mb-5 mb-md-0">
                                <img src={stat1} className="img" alt="Full stats" title="Full stats" />
                            </div>
                            <div className="flex-c col-md-2"/>
                            <div className="flex-c align-items-center text-c col-md-5">
                                <img src={stat2} className="img" alt="Symptom stats" title="Symptom stats" />
                            </div>
                        </div>
                    </>
                ):(
                    <>
                    {/* Client Teams */}
                        <div className="flex-c row align-items-center mb-5">
                            <div className="flex-c align-items-center text-c col-12 col-md-10">
                                <h4 className="font-48 mb-0">Teams</h4>
                                <p className="lh-24 mb-5">
                                    You may share your scores by joining a team. To do this, you must request & apply that team's unique team code.
                                </p>
                                <img src={team2} className="img mt-5" alt="Teams view" title="Teams view" />
                            </div>
                        </div>
                    </>
                )}
                
            </div>
            <div className="flex-c py-5 bckg-secondary mt-5">
                <div className="flex-c container pt-5">
                    <div className="flex-c align-items-center text-c row mb-5">
                        <h4 className="font-48 white m-0 p-0 mb-5">
                            {clinicianValue ?
                            'Empower Your Practice with Insights.'
                            :
                            'Elevate Your Mental Health & Wellness.'
                            }
                        </h4>
                        <div className="flex-c flex-sm-r flex-auto col-12 col-md-6 col-lg-3 mt-2">
                            <Link
                                type="button"
                                className="btn btn-white mb-3 mb-sm-0 mr-sm-2"
                                title="Get Started Free"
                                // to="/disclaimer"
                                to="/maintenance">
                                Get Started Free
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Explore
